import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Mit adományozzak?`}</h1>
    <h2>{`Pénzbeli támogatás`}</h2>
    <h3>{`1.`}</h3>
    <p><strong parentName="p">{`Bankszámlaszámunk:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`11710002-20083777`}</strong></p>
    <p>{`Külföldről történő utaláshoz:`}<br parentName="p"></br>{`
`}{`IBAN: HU62 1171 0002 2008 3777 0000 0000`}<br parentName="p"></br>{`
`}{`SWIFT (BIC): OTPV-HU-HB OTP Bank / Budapest, 1102 Kőrösi Csoma sétány 6.`}</p>
    <p>{`EURO számlánk:`}<br parentName="p"></br>{`
`}{`11763103-13106883`}</p>
    <p>{`Külföldről történő utaláshoz:`}<br parentName="p"></br>{`
`}{`IBAN: HU15 1176 3103 1310 6883 0000 0000`}<br parentName="p"></br>{`
`}{`SWIFT (BIC): OTPVHUHB`}<br parentName="p"></br>{`
`}{`OTP Bank / Budapest, 1102 Kőrösi Csoma sétány 6.`}</p>
    <h3>{`2.`}</h3>
    <p>{`Közvetlen `}<strong parentName="p">{`bankkártyás adományozással`}</strong>{`:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://adjukossze.hu/adomanygyujtes/noe-allatotthon-alapitvany-teljes-nev-rakosmenti-noe-barkaja-allatotthon-alapitvany-1050/tamogatom"
      }}>{`https://adjukossze.hu/adomanygyujtes/noe-allatotthon-alapitvany-teljes-nev-rakosmenti-noe-barkaja-allatotthon-alapitvany-1050/tamogatom`}</a></p>
    <h3>{`3.`}</h3>
    <p>{`Illetve a `}<strong parentName="p">{`NOÉ Állatotthon ADHAT Segélyvonalán`}</strong>{`, melyre mostantól SMS is küldhető!!!!!`}<br parentName="p"></br>{`
`}{`Hívd a 13600-as telefonszámot (mindenféle előhívó nélkül!), majd az automata bejelentkezése után add meg a NOÉ Állatotthon kódját: 63!
Ha könnyebb az sms, akkor a 13600-as számra küld a 63-at. Egy telefonhívás vagy egy üzenet értéke 250 Ft. Ennyit kapunk, és ennyit is számláznak az adott telefonszámlára!`}<br parentName="p"></br>{`
`}{`Jelenleg a T-Home és T-Mobile (jelenleg csak telefonhívás!), valamint a Telenor, Vodafone és az Invitel hálózatából érhető el a szolgáltatás.`}</p>
    <h2>{`Tárgyi adományok`}</h2>
    <p>{`Látogatóink gyakran kérdezik, mire lenne épp a legnagyobb szükségünk az állatotthonban, mit hozzanak, mit adományozzanak? Ezért gondozóinkat minden héten megkérdezzük, hiszen ők tudják megmondani, miből vagyunk épp kifogyva vagy mi az, ami elromlott, eltörött, elhasználódott az állatok körüli munkában. Jelenleg tehát az alábbiakra lenne szükségünk:`}</p>
    <h3>{`KULLANCS RIASZTÓ SPOT-ON BÁRMILYEN MENNYISÉGBEN!`}</h3>
    <h3>{`GYÓGYSZEREK`}</h3>
    <p>{`Karsivan, Canidryl, Carprox, Rimadyl, Meloxidyl, Metacam, HepaPet, Vectra 3D spot-on, Advocate spot-on, Moxiclear spot-on, Synolux, Pantoprazol, Augmentin tabletta, Prednisolon, Milgamma`}</p>
    <p>{`Neogranormon kenőcs, Nizoral son Aloe First spray, Fültisztító folyadék (kutya, macska)`}</p>
    <h3>{`BALESETES ÉS FERTŐZŐ RÉSZLEGÜNKBE:`}</h3>
    <ul>
      <li parentName="ul">{`tiszta, jó nedvszívó, nagy méretű rongyra`}</li>
      <li parentName="ul">{`papírtörlő, WC papír minden mennyiségben`}</li>
      <li parentName="ul">{`gumikesztyűre L-es, M-es és S-es méretben`}</li>
      <li parentName="ul">{`nagyobb méretű szemeteszsák`}</li>
      <li parentName="ul">{`kéz- és felületfertőtlenítő (pl.: domestos, flóraszept) szerek`}</li>
      <li parentName="ul">{`Aluminium spray`}</li>
      <li parentName="ul">{`500g-os gyógyszertári vattára`}</li>
    </ul>
    <h3>{`TÁPOK, ÁLLATELEDEL:`}</h3>
    <ul>
      <li parentName="ul">{`gyógytápok, elsősorban hypoallergen, gastro intestinal, gastro intestinal low fat, renal, hepatic`}</li>
      <li parentName="ul">{`száraztápra minden minőségben és mennyiségben kutyáknak és cicáknak is`}</li>
      <li parentName="ul">{`SENIOR tápra idős állataink részére`}</li>
      <li parentName="ul">{`KÖLYÖKTÁPRA kutyák és cicák részére is`}</li>
      <li parentName="ul">{`beteg kutyáink számára szükségünk lenne minél jobb minőségű (RC, Eukanuba stb.) száraztápra`}</li>
      <li parentName="ul">{`galamboknak, baromfinak való szemes táp`}</li>
      <li parentName="ul">{`díszmadáreledel`}</li>
      <li parentName="ul">{`tengerimalac és nyúl táp`}</li>
    </ul>
    <h3>{`EGYEBEK:`}</h3>
    <ul>
      <li parentName="ul">{`szállítóbox (kutya és macska)`}</li>
      <li parentName="ul">{`pórázra minden mennyiségben, színben, méretben`}</li>
      <li parentName="ul">{`lombgereblyére(nem műanyag végűre), lapátra, ásóra, hólapátra (erősebb, jobb minőségű), talicskára, lapátra, vödrökre, kaszára (minél hosszabb nyelűre)`}</li>
      <li parentName="ul">{`tűzifára`}</li>
      <li parentName="ul">{`faforgácsra minden mennyiségben (rókák, borzok, nyulak alá). A fűrészpor nem jó, és a nagyon szemetes forgács sem!`}</li>
      <li parentName="ul">{`benzines fűkaszára`}</li>
      <li parentName="ul">{`szénára`}</li>
      <li parentName="ul">{`bármilyen osztású és vastagságú vasbeton-hálóra`}</li>
      <li parentName="ul">{`új kennelek építéséhez `}<a href="http://www.noeallatotthon.hu/images/halo.jpg" target="_blank" rel="noreferrer">{`ilyen fémhálóra`}</a></li>
      <li parentName="ul">{`vasanyagra, pl. 40x40-es zártszelvényre.`}</li>
      <li parentName="ul">{`macskaalomra (bármilyen fajta megfelel, a legolcsóbb is!) Macskaalom helyett tökéletesen tudjuk használni az eredetileg tüzelőanyagként forgalmazott un. pelletet, ami jóval olcsóbb és könnyebb (így szállítása is könnyebben megoldható), mint az alom.`}</li>
      <li parentName="ul">{`kutyaházak foltozására és építésére való alapanyagra (hajópadló, OSB lapok, deszkák, lécek, zsindelyek, stb.)`}</li>
      <li parentName="ul">{`talicskára, vödörre`}</li>
    </ul>
    <p>{`Köszönjük!`}</p>
    <p><strong parentName="p">{`FIGYELEM:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`NEM`}</strong>{` tudunk használni például szőnyegeket, matracokat, kis méretű rongyokat, emberi ruhákat; semmit, ami láthatóan piszkos, szőrös, stb. Csak és kizárólag tiszta, jó nedvszívó rongyokat (ágynemű, törölköző, lepedő, stb.) tudunk használni a kórházban állataink alá. Kérjük, csak olyasmit hozzon, amit Ön is berakna vérző saját állata alá. Gyakran kapunk felszedett, piszkos szőnyegeket, nylon és csipkefüggönyöket, gyerekruhákat, stb, melyeket nem tudunk használni és szemétként való elszállításuk jelentős költséget jelent. A pehelypaplanokat a kutyusok pár perc alatt széttépik és órákig kell takarítani utánuk, így sajnos a paplanokat sem tudjuk használni.`}</p>
    <p>{`Tápokat, gyógyszereket fajtájuktól függően a lejárattól számított kb. fél-egy éven belül tudunk felhasználni, vitaminkészítményeket kb. 1 éven belül. Kérjük, ennél régebben lejárt készítményeket NE adományozzanak, mivel ezeket sajnos nem adhatjuk már oda állatainknak, így megsemmisítésük, szállításuk, stb. csak költséget és utánjárást jelent az állatotthonnak.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      